/* = Framework and 3rd Party Libraries.
-------------------------------------------------------------- */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

body {
  font-family: "brandon-grotesque";
  font-size: 24px;
  color: #4D4D4D;
  letter-spacing: 0.03em;
  background: #FDFDFD; }

form input[type=text], form input[type=email], form input[type=tel] {
  border-radius: 3px;
  border: 1px solid #EAEAEA;
  height: 40px;
  width: 260px;
  background: #FAFAFA;
  font-family: "brandon-grotesque";
  font-size: 16px;
  color: #4D4D4D;
  padding: 0 20px;
  font-weight: 300; }

form input[type=tel] {
  width: 180px; }

form button {
  position: relative;
  background: #9FC9D2;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 13px;
  font-family: "brandon-grotesque";
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  display: inline-block;
  height: 40px;
  padding-top: 5px;
  border: none;
  width: 130px;
  letter-spacing: 0.03em; }
  form button span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    line-height: 47px; }

.hidden {
  display: none; }

.main {
  line-height: 31px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin: 0 auto; }
  .main section.content {
    text-align: center;
    color: #4D4D4D; }
    .main section.content h1 {
      display: block;
      width: 135px;
      height: 99px;
      background: url(images/logo.png);
      background-size: cover;
      margin: 0 auto; }
      .main section.content h1 span {
        display: none; }
    .main section.content h2, .main section.content p {
      font-size: 24px;
      margin: 0;
      padding: 0; }
    .main section.content h2 {
      font-weight: 700;
      margin-bottom: 8px; }
    .main section.content p {
      font-weight: 300;
      margin-bottom: 35px; }
  .main .branding {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: #EAEAEA 1px solid; }
  .main div.content {
    margin-bottom: 19px;
    padding-bottom: 31px;
    border-bottom: #EAEAEA 1px solid; }
  .main .mailing-list h3, .main .text-me h3 {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5em; }
  .main .actions a {
    display: inline-block;
    width: 140px;
    height: 47px; }
    .main .actions a img {
      width: 100%; }
    .main .actions a.download-on-app-store {
      margin-right: 13px; }
    .main .actions a.text-me-the-app {
      position: relative;
      background: #9FC9D2;
      color: #FFFFFF;
      text-decoration: none;
      font-size: 13px;
      text-align: center;
      border-radius: 5px;
      text-transform: uppercase; }
      .main .actions a.text-me-the-app span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        line-height: 47px; }
  .main .slideshow-mobile {
    display: none; }
  .main section.preview {
    position: relative; }
    .main section.preview .frame {
      position: relative; }
  .main .slideshow-desktop {
    position: absolute;
    top: 62px;
    right: 17px;
    bottom: 62px;
    left: 16px;
    border-radius: 6px;
    overflow: hidden;
    line-height: 0;
    background: #FDFDFD; }
    .main .slideshow-desktop img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: opacity 2000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .main .slideshow-desktop img.show {
        opacity: 1; }
  .main .slideshow-mobile {
    position: relative; }
    .main .slideshow-mobile img {
      position: absolute;
      top: 0;
      left: -5%;
      width: 110%;
      transform: scale(1) translateY(0) translateX(0);
      opacity: 0;
      transition: opacity 2000ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 10000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .main .slideshow-mobile img.show {
        opacity: 1;
        transform: scale(1.05) translateY(-15px) translateX(10px); }
        .main .slideshow-mobile img.show.up {
          transform: scale(1.05) translateY(-15px) translateX(0); }
        .main .slideshow-mobile img.show.down {
          transform: scale(1.05) translateY(15px) translateX(0); }
        .main .slideshow-mobile img.show.right {
          transform: scale(1.05) translateY(0) translateX(15px); }
        .main .slideshow-mobile img.show.left {
          transform: scale(1.05) translateY(0) translateX(-15px); }
        .main .slideshow-mobile img.show.up-right {
          transform: scale(1.05) translateY(-15px) translateX(15px); }
        .main .slideshow-mobile img.show.up-left {
          transform: scale(1.05) translateY(-15px) translateX(-15px); }
        .main .slideshow-mobile img.show.down-right {
          transform: scale(1.05) translateY(15px) translateX(15px); }
        .main .slideshow-mobile img.show.down-left {
          transform: scale(1.05) translateY(15px) translateX(-15px); }

footer {
  margin-top: 120px !important;
  display: block;
  font-size: 13px;
  font-weight: 500; }
  footer ul {
    display: block;
    width: 100%;
    text-align: center;
    letter-spacing: 0.4pt; }
    footer ul li {
      display: inline-block;
      color: #AAAAAA;
      text-transform: uppercase; }
      footer ul li a {
        color: #4D4D4D;
        margin-right: 35px;
        text-decoration: none; }
        footer ul li a:hover {
          color: #9FC9D2; }

@media (min-width: 880px) {
  .main, footer {
    width: 880px;
    margin: 0 auto; }
  .main section.preview {
    width: 40%;
    padding-top: 120px; }
  .main section.content {
    padding: 120px 5% 0;
    width: 50%; }
  .preview {
    padding: 50px;
    box-sizing: border-box; }
    .preview .frame {
      background: url(images/preview-frame-desktop.png);
      background-size: cover;
      width: 100%;
      padding-top: 204%; } }

@media (max-width: 879px) {
  body {
    font-size: 20px; }
  .preview {
    position: relative;
    display: block;
    min-height: 200px;
    max-height: 350px;
    background: #f3f3f3;
    width: 100%;
    overflow: hidden; }
    .preview::before {
      content: '';
      display: block;
      padding-top: 25%; }
  .main section.content {
    max-width: 85%;
    margin: 0 auto; }
    .main section.content h1 {
      margin-top: 40px;
      width: 95px;
      height: 65px; }
    .main section.content h2, .main section.content p {
      font-size: 20px;
      margin: 0;
      padding: 0; }
    .main section.content h2 {
      margin-bottom: 15px; }
    .main section.content p {
      margin-bottom: 40px; }
  .main .branding {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 35px; }
  .main .actions {
    border-top: #EAEAEA 1px solid;
    padding-top: 20px; }
    .main .actions::before {
      content: 'Get the app.';
      display: block;
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 5px; }
    .main .actions a.download-on-app-store {
      margin-right: 0; }
    .main .actions a.text-me-the-app {
      display: none; }
  .main .mailing-list h3, .main .text-me h3 {
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 10px; }
  .main .mailing-list form input, .main .text-me form input {
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box; }
  .main .mailing-list form button, .main .text-me form button {
    width: 100%; }
  .main section.preview .frame {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .main .slideshow-desktop {
    display: none; }
  .main .slideshow-mobile {
    display: block; }
  footer {
    margin-top: 60px; }
    footer ul {
      padding-left: 0;
      padding-right: 0; }
      footer ul li {
        width: 20%; }
        footer ul li a {
          margin-right: 0; }
        footer ul li.copyright {
          width: 100%;
          margin-top: 15px; } }

/*# sourceMappingURL=foodim-lp.css.map */